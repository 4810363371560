import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/loading_hourglass.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
