import { createAction, props } from "@ngrx/store";
import { STORE_KEY } from "./key";
import { IAuth, IProfile } from "./auth.reducer";

const key = STORE_KEY;

export const setAuth = createAction(`[${key}] Save Auth Profile`, props<{ profile: IProfile, isAuthenticated: boolean }>());
export const purgeAuth = createAction(`[${key}] Purge Auth`);

// Effects
export const auth0Setup = createAction(`[${key}] Setup Auth`);
export const login = createAction(`[${key}] Request Login`);
export const loginWithSession = createAction(`[${key}] Request Login with State`, props<{ session: string }>());
export const handleAuth0Redirect = createAction(`[${key}] Handle Redirect from Auth0`);
export const logout = createAction(`[${key}] logut`);