import { Injectable } from "@angular/core";
import createAuth0Client, {
  GetUserOptions,
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  User,
} from "@auth0/auth0-spa-js";
import {
  from,
  of,
  Observable,
  throwError,
  forkJoin,
  BehaviorSubject,
} from "rxjs";
import { tap, catchError, concatMap, shareReplay, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IAuth } from "./auth.reducer";
import { nanoid } from "nanoid";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  emailUser: string;
  clientId: string;
  clientName: string;
  clientInfo$: BehaviorSubject<IClientInfo> = new BehaviorSubject(null);
  userInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  
  private publicToken: string = null;
  private isPublic: boolean = false;
  private publicUserId: string;

  getPublicStatus() {
    return this.isPublic;
  }

  setPublicStatus(status: boolean) {
    this.isPublic = status;
  }

  // Create an observable of Auth0 instance of client
  private auth0Client$ = (from(
    createAuth0Client({
      domain: "login.customerglu.com",
      client_id: "mQRoRzEsox0qUg2etEIWTjv1fnpfQSoy",
      redirect_uri: `${environment.app}/auth/cb/pp`,
      cacheLocation: "localstorage",
      useRefreshTokens: true,
      audience: "https://api.customerglu.com/campaign" //NEW
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1),
    catchError((err) => throwError(err))
  );

  setPublicToken(token: string) {
    this.publicToken = token;
  }

  generatePublicUserId() {
    let publicUserId = localStorage.getItem("X-Glu-Public-UserId");
    if(publicUserId) {
      this.publicUserId = publicUserId;
    } else {
      let id = nanoid();
      this.publicUserId = `GLU_PH_${id}`;
      localStorage.setItem("X-Glu-Public-UserId", this.publicUserId);
    }

    return this.publicUserId;
  }

  getPublicUserId() {
    return this.publicUserId;
  }

  getStateValue() {
    return {
      state: this.clientInfo$.value,
    };
  }
  updateClientState(
    state: IClientInfo,
  ) {
    this.clientInfo$.next(state);
  }

  updateUserState(
    state: any,
  ) {
    this.userInfo$.next(state);
  }



  public isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );
  private handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  public getUser$(options?: GetUserOptions): Observable<User> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options)))
    );
  }

  public Auth0Setup$() {
    return this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        let auth: IAuth = {
          isAuthenticated: false,
          profile: null,
          auth0Initilized: true,
        };
        if (loggedIn) {
          return this.getUser$().pipe(
            map((user) => {
              auth.profile = user;
              auth.isAuthenticated = true;
              return auth;
            })
          );
        }
        return of(auth);
      })
    );
  }

  public login$(session: string = "", redirectPath: string = "/") {
    return this.auth0Client$.pipe(
      tap((client: Auth0Client) => {
        client.loginWithRedirect({
          redirect_uri: `${environment.app}/auth/cb/pp`,
          appState: { target: redirectPath, session },
        });
      })
    );
  }

  public handleAuthCallback$() {
    const params = window.location.search;
    if(params.includes("error=access_denied")) {
      console.log("ACCESS_DENIED");
      return throwError("ACCESS_DENIED");
    } else if (params.includes("error=unauthorized")) {
      console.log("UNAUTHORIZED_ACCESS");
      return throwError("UNAUTHORIZED_ACCESS");
    }
    // Check auth0 params
    else if (params.includes("code=") && params.includes("state=")) {
      // Path to redirect to after login processsed
      let targetRoute: string;
      let session: string;
      return this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap((cbRes) => {
          // Get and set target redirect route from callback results
          targetRoute =
            cbRes.appState && cbRes.appState.target
              ? cbRes.appState.target
              : "/";
          session = cbRes.appState && cbRes.appState.session
              ? cbRes.appState.session
              : null;
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return forkJoin({
            auth: this.Auth0Setup$(),
            session: of(session),
            targetRoute: of(targetRoute),
          });
        })
      );
    } else {
      throw "Invalid Params";
    }
  }

  public logout$() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: "mQRoRzEsox0qUg2etEIWTjv1fnpfQSoy",
        returnTo: `${environment.app}`,
      });
    });
  }

  public getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
    if (this.publicToken) {
      return of(this.publicToken);
    } else {
      return this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
      );
    }
  }
}

export interface IClientInfo {
  clientId: string,
  clientName: string,
  isRewardInitEnabled: boolean,
  email: string,
  apiKey: string,
  eventsLogUrl: string
}
