import { Component, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { auth0Setup } from "./auth/auth.actions";
import { ApiService } from "./core/services/api.service";
import { ScriptsService } from "./core/services/scripts.service";
import { SegmentLoggerService } from "./core/services/segment-logger.service";
import { forkJoin } from 'rxjs';
import { AuthService } from "./auth/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private store: Store<any>,
    public vcRef: ViewContainerRef,
    private scriptLoader: ScriptsService,
    private authService: AuthService,
    private apiService: ApiService
    ) {
      this.authService.setPublicStatus(window.location.href.includes("public"));
    this.store.dispatch(auth0Setup());
    this.authService.isAuthenticated$.subscribe(res => {
      if (res) {
        scriptLoader.loadScript('cg-web-sdk').then(res => {
          forkJoin(
            [
              this.authService.getUser$(),
              this.apiService.getClientInfo()
            ]
          ).subscribe(([
            user,
            clientInfo
          ]) => {
            // console.log(user, clientInfo)
            const userProps = {
              "Username": user?.name,
              "User Email": user?.email,
              "User Client ID": clientInfo.clientId,
              "User Nickname": user?.nickname,
              "User Project Name": clientInfo?.name,
              "User Billing Plan": clientInfo?.billingPlan,
              "User Current Environment": clientInfo?.environment,
              "User Region": clientInfo?.region,
              "User Timezone": clientInfo?.timezone,
              "User Version": clientInfo?.version,
              "User Timestamp": new Date()
            }
            const glu = new window['CustomerGlu'](environment.webSdkApiKey, { userId: user.sub.split("|")[1] }, { customAttributes: { ...userProps } });

          })
        }).catch(err => {
          console.log(err);
        });
      }
    });
  }
}
