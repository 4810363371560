import { createReducer, Action, on } from "@ngrx/store";
import * as authActions from "./auth.actions";

export interface IProfile {
  nickname?: string;
  name?: string;
  picture?: string;
  app_metadata?: {
    clientName: string;
  };
}

export interface IAuth {
  profile: IProfile;
  isAuthenticated: boolean;
  auth0Initilized: boolean;
}

const initialState: IAuth = {
  profile: null,
  isAuthenticated: false,
  auth0Initilized: false,
};

const _auth = createReducer<IAuth>(
  initialState,
  on(authActions.auth0Setup, () => ({
    profile: null,
    isAuthenticated: null,
    auth0Initilized: false,
  })),
  on(authActions.setAuth, (state, { profile, isAuthenticated }) => ({
    profile,
    isAuthenticated,
    auth0Initilized: true,
  })),
  on(authActions.purgeAuth, (state) => ({
    ...state,
    profile: null,
    isAuthenticated: false,
  }))
);

export function authReducer(state: IAuth, action: Action) {
  return _auth(state, action);
}
