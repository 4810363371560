import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material/material.module";
import { SheetComponent } from "./components/sheet/sheet.component";
import { LoadingIndocatorComponent } from "./components/loading-indocator/loading-indocator.component";
import { MessageCardComponent } from "./components/message-card/message-card.component";
import { AlertComponent } from "./components/alert/alert.component";
import { NumbericValidatorDirective } from './directives/numberic-validator.directive';
import { FlatButtonComponent } from './components/custom-button/flat-button/flat-button.component';
import { SafeHtmlPipe } from "./pipes/safeHtml.pipe";
import { AsyncFileUploaderComponent } from './components/async-file-uploader/async-file-uploader.component';
import { TestingShelfSidebarComponent } from './components/testing-shelf-sidebar/testing-shelf-sidebar.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { IconButtonComponent } from './components/custom-button/icon-button/icon-button.component';
import { ToggleDirective } from './directives/toggle.directive';
import { LottieModule } from 'ngx-lottie';
import { TestWebhookComponent } from './components/testing-shelf-inners/test-webhook/test-webhook.component';
import { BadgeComponent } from './components/badge/badge.component';
import { TestCgInstallationComponent } from './components/testing-shelf-inners/test-cg-installation/test-cg-installation.component';
import { EventLogsComponent } from './components/testing-shelf-inners/event-logs/event-logs.component';
import { SendTestNudgeComponent } from './components/testing-shelf-inners/send-test-nudge/send-test-nudge.component';
import { CopyDirective } from './directives/copy.directive';
import { InstallCustomerGluComponent } from './components/install-customer-glu/install-customer-glu.component';
import { NewWebhookComponent } from './components/new-webhook/new-webhook.component';
import { SendingEventsComponent } from './components/sending-events/sending-events.component';
import { SettingNudgeComponent } from './components/setting-nudge/setting-nudge.component';
import { MoengageComponent } from './components/sending-events/moengage/moengage.component';
import { SegmentComponent } from './components/sending-events/segment/segment.component';
import { ServerComponent } from './components/sending-events/server/server.component';
import { WebengageComponent } from './components/sending-events/webengage/webengage.component';
import { ApnComponent } from './components/setting-nudge/apn/apn.component';
import { FcmComponent } from './components/setting-nudge/fcm/fcm.component';
import { CardFooterComponent } from './components/card-footer/card-footer.component';
import { CodeSnippetComponent } from './components/code-snippet/code-snippet.component';
import { CopyTextDirective } from './directives/copy-text.directive';
import { CopyTextInputDirective } from './directives/copy-text-input.directive';
import { DragDropFileUploaderComponent } from './components/drag-drop-file-uploader/drag-drop-file-uploader.component';
import { DragAndDropDirective } from "./directives/drag-and-drop.directive";
import { UploadCsvModalComponent } from './components/upload-csv-modal/upload-csv-modal.component';

import player from 'lottie-web';
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { ContenteditableValueAccessor } from "./directives/content-value-accessor.directive";
import { ClevertapComponent } from './components/sending-events/clevertap/clevertap.component';
import { GoogleComponent } from './components/sending-events/google/google.component';
import { TalononeComponent } from './components/sending-events/talonone/talonone.component';
import { CustomerioComponent } from './components/sending-events/customerio/customerio.component';
import { AmplitudeComponent } from './components/sending-events/amplitude/amplitude.component';
import { MixpanelComponent } from './components/sending-events/mixpanel/mixpanel.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    SheetComponent,
    LoadingIndocatorComponent,
    MessageCardComponent,
    AlertComponent,
    NumbericValidatorDirective,
    FlatButtonComponent,
    SafeHtmlPipe,
    CopyTextDirective,
    AsyncFileUploaderComponent,
    TestingShelfSidebarComponent,
    CustomIconComponent,
    IconButtonComponent,
    ToggleDirective,
    TestWebhookComponent,
    BadgeComponent,
    TestCgInstallationComponent,
    EventLogsComponent,
    SendTestNudgeComponent,
    CopyDirective,
    InstallCustomerGluComponent,
    NewWebhookComponent,
    SendingEventsComponent,
    SettingNudgeComponent,
    MoengageComponent,
    SegmentComponent,
    ServerComponent,
    WebengageComponent,
    ApnComponent,
    FcmComponent,
    CardFooterComponent,
    CodeSnippetComponent,
    CopyTextDirective,
    CopyTextInputDirective,
    DragDropFileUploaderComponent,
    DragAndDropDirective,
    UploadCsvModalComponent,
    ScrollToTopDirective,
    ContenteditableValueAccessor,
    ClevertapComponent,
    GoogleComponent,
    TalononeComponent,
    CustomerioComponent,
    AmplitudeComponent,
    MixpanelComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, LottieModule.forRoot({player: playerFactory})],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SheetComponent,
    LoadingIndocatorComponent,
    MessageCardComponent,
    AlertComponent,
    NumbericValidatorDirective,
    FlatButtonComponent,
    SafeHtmlPipe,
    CopyTextDirective,
    AsyncFileUploaderComponent,
    TestingShelfSidebarComponent,
    CustomIconComponent,
    IconButtonComponent,
    ToggleDirective,
    CopyDirective,
    CopyTextInputDirective,
    TestWebhookComponent,
    BadgeComponent,
    TestCgInstallationComponent,
    EventLogsComponent,
    SendTestNudgeComponent,
    InstallCustomerGluComponent,
    NewWebhookComponent,
    SendingEventsComponent,
    SettingNudgeComponent,
    MoengageComponent,
    SegmentComponent,
    ServerComponent,
    WebengageComponent,
    ApnComponent,
    FcmComponent,
    CardFooterComponent,
    CodeSnippetComponent,
    DragAndDropDirective,
    DragDropFileUploaderComponent,
    ScrollToTopDirective,
    ClevertapComponent,
    ContenteditableValueAccessor,
    TalononeComponent,
    CustomerioComponent,
    AmplitudeComponent,
    MixpanelComponent
  ],
})
export class SharedModule { }
