import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { ClientGuard } from "./auth/client.guard";

const routes: Routes = [
  {
    path: "locked",
    loadChildren: () =>
      import("./pages/locked-dashboard/locked-dashboard.module").then(
        (m) => m.LockedDashboardModule
      ),
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./pages/onboarding/onboarding.module").then(
        (m) => m.OnboardingModule
      ),
  },
  {
    path: "docs",
    loadChildren: () =>
      import("./pages/docs/docs.module").then(
        (m) => m.DocsModule
      ),
  },
  {
    path: "public",
    loadChildren: () =>
      import("./pages/public/public.module").then((m) => m.PublicModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    // canLoad: [AuthGuard, ClientGuard],
    // canActivate: [AuthGuard, ClientGuard],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
