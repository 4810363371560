import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, exhaustMap, tap, mergeMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import * as authActions from "./auth.actions";
import { Router } from "@angular/router";
import { Action } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { SnackBarService } from "../core/services/snack-bar.service";
import { forkJoin } from "rxjs";
import { ApiService } from "../core/services/api.service";

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.login),
        exhaustMap((action) => this.authService.login$())
      ),
    { dispatch: false }
  );

  loginWithSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginWithSession),
        exhaustMap((action) => this.authService.login$(action.session))
      ),
    { dispatch: false }
  );

  handleAuth0Redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.handleAuth0Redirect),
        exhaustMap((action) => {
          return forkJoin([
            this.authService.handleAuthCallback$(),
            this.apiService.getClientInfo()
          ]).pipe(
            mergeMap(([{ auth, targetRoute, session }, clientInfo]) => {
              this.router.navigate([targetRoute], { replaceUrl: true });
              let actions: Action[] = [];
              if(environment.production === true) {
                window['analytics'].track('Logged In', {
                  "Username": auth['profile']['nickname'],
                  "User Email": auth['profile']['email'],
                  "User Timestamp": new Date(),
                  "User Project Name": clientInfo?.name,
                  "User Billing Plan": clientInfo?.billingPlan,
                  "User Current Environment": clientInfo?.environment,
                  "User Region": clientInfo?.region,
                  "User Timezone": clientInfo?.timezone,
                  "User Version": clientInfo?.version,
                  "User Client ID": auth['profile']['https://customerglu.com/clientId']
                });
              }
              return actions;
            }),
            catchError((err: string) => {
              if(err == "ACCESS_DENIED") {
                this.snackBarService.error('Your IP address is not authorised. Please try again or contact support@customerglu.net');
                setTimeout(()=> {
                  this.authService.logout$();
                }, 3000);
              } else if (err == "UNAUTHORIZED_ACCESS") {
                // this.snackBarService.error('Your access has been revoked, Please contact CustomerGlu Team.');      
              }else {
                this.router.navigate([""], { replaceUrl: true });
              }
              throw err;
            })
          );
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        tap((action) => {
          this.authService.logout$();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService,
    private apiService: ApiService
  ) {}
}
