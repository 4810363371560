import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const scriptsStore: Scripts[] = [
  {
    name: "retool",
    src: "https://cdn.tryretool.com/embed.js"
  },
  {
    name: "cg-web-sdk",
    src: environment.webSdkUrl
  },
  {
    name: "qrcode",
    src: "https://assets.customerglu.com/dashboard/js/qrcode.js"
  },
  {
    name: "confetti",
    src: "https://cdn.jsdelivr.net/npm/canvas-confetti@1.6.0/dist/confetti.browser.min.js"
  }
]

@Injectable({
  providedIn: 'root'
})

export class ScriptsService {

  scripts: {
    [key: string]: {
      name: string;
      src: string;
      loaded: boolean;
    }
  } = {};

  constructor() {
    scriptsStore.forEach(script => {
      this.scripts[script.name] = {
        name: script.name,
        src: script.src,
        loaded: false
      }
    })
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({
          script: name,
          loaded: true
        });
      } else {
        if (!this.scripts[name]?.src) {
          reject({script: name, loaded: false});
          return;
        }
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({
            script: name,
            loaded: true
          });
        }
        script.onerror = error => {
          reject({script: name, loaded: false})
        }
        document.head.appendChild(script)
      }
    });
  }
}

export interface Scripts {
  name: string;
  src: string;
}
