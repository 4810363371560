import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { UploadingStatusComponent } from "./uploading-status.component";

@NgModule({
  declarations: [
    UploadingStatusComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [UploadingStatusComponent],
})
export class UploadingStatusModule {}
