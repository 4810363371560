import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ScriptsService } from './scripts.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingModalComponent } from 'src/app/modules-v1/loading-modal/loading-modal.component';
import { LOADING_DIALOG_CONFIG } from 'src/app/modules-v1/loading-modal/loading-modal-config';

declare var confetti: any;

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  actionButtonLabel: string = 'Retry';
  action: boolean = false;
  setAutoHide: boolean = true;
  autoHide: number = 3000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  addExtraClass: boolean = false;

  constructor(public snackBar: MatSnackBar,
    private scriptsService: ScriptsService,
    private dialog: MatDialog) { }

  loadSnackBar(message) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = 'default-notification-overlay';
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    return this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  info(message) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = 'info-notification-overlay';
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    return this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  success(message) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = 'success-notification-overlay';
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    return this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  error(message) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = 'error-notification-overlay';
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    return this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  warning(message) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = 'warning-notification-overlay';
    // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    return this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  confetti() {
    this.scriptsService.loadScript("confetti").then(res => {
      var count = 200;
      var defaults = {
        origin: { y: 0.7 }
      };
      
      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
          zIndex: 9999
        }));
      }
      
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    })
  }

  loader() {
    return this.dialog.open(LoadingModalComponent, LOADING_DIALOG_CONFIG);
  }
}
