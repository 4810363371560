export const environment = {
   production: true,
   env: 'PRODUCTION',
   app: process.env.APP_URL,
   apiUrl: process.env.API_URL || 'https://api.customerglu.com',
   productFruitApiKey: process.env.PRODUCT_FRUIT_API_KEY,
   templateStaticKey: process.env.TEMPLATE_STATIC_KEY,
   webSdkUrl: process.env.WEB_SDK_URL,
   webSdkApiKey: process.env.WEB_SDK_API_KEY
};
