import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType } from "@angular/common/http";
import { AuthService } from "src/app/auth/auth.service";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UploadingStatusComponent } from "../../modules/uploading-status/uploading-status.component"
import { of, throwError } from "rxjs";
import { DOMAIN } from "src/app/config/dev";
import { SnackBarService } from "src/app/core/services/snack-bar.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {

  private clientInfo: any = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog) {
    }

  callApi<IResponse = any>(method: string, url: string, body?: any, useToken: boolean = true, customHeader: object = null) {
    // use the passed token
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    for (let header in customHeader) {
      if (customHeader.hasOwnProperty('Content-Type') && customHeader['Content-Type'] === 'multipart/form-data') {
        headers = headers.set('Content-Type', customHeader[header]);
      } else {
        headers = headers.append(header, customHeader[header]);
      }
    }
    if (useToken) {
      return this.authService.getTokenSilently$({ignoreCache: false}).pipe(
        mergeMap((accessToken) => {
          headers = headers.append("authorization", `Bearer ${accessToken}`);
          if(this.authService.getPublicStatus()) {
            headers = headers.append("X-Token", accessToken);
          }
          return this.http.request<IResponse>(method, url, { body, headers });
        }),
        catchError((err) => {
          if(err.error === 'login_required') {
            this.authService.logout$();
          }
          return throwError(err);
        })
      );
    } else {
      return this.http.request<IResponse>(method, url, { body, headers });
    }
  }

  uploadAsset(file: File) {
    const formData = new FormData();
    formData.append('gluAsset', file, file.name);
    let headers = new HttpHeaders();
    let statusDialogRef: MatDialogRef<UploadingStatusComponent>;
    statusDialogRef = this.dialog.open(UploadingStatusComponent, {
      height: '100px',
      width: '260px',
      disableClose: true,
      panelClass: 'campaign-status',
    });
    return this.authService.getTokenSilently$({ignoreCache: false}).pipe(
      mergeMap((accessToken) => {
        headers = headers.append("authorization", `Bearer ${accessToken}`);
        if(this.authService.getPublicStatus()) {
          headers = headers.append("X-Token", accessToken);
        }
        // let statusDialogRef: MatDialogRef<UploadingStatusComponent>;
        // statusDialogRef = this.dialog.open(UploadingStatusComponent, {
        //   height: '100px',
        //   width: '200px',
        //   disableClose: false,
        //   panelClass: 'campaign-status',
        // });
        return this.http.put(`${DOMAIN}/integrations/v1/assets`, formData, {headers, observe: 'events'}).pipe(
          tap((resp)=> {
            statusDialogRef.close();
          })
        );
      })
    );
  }

  uploadFormData(body: any, url:string) {
    let headers = new HttpHeaders();
    return this.authService.getTokenSilently$({ignoreCache: false}).pipe(
      mergeMap((accessToken) => {
        headers = headers.append("authorization", `Bearer ${accessToken}`);
        if(this.authService.getPublicStatus()) {
          headers = headers.append("X-Token", accessToken);
        }
        return this.http.post(url, body, {headers, observe: 'events'}).pipe();
      })
    );
  }

  uploadCouponCSV(file: File, url: string, campaignId: string) {
    const formData = new FormData();
    formData.append('coupons', file, file.name);
    formData.append('campaignId', campaignId);
    let headers = new HttpHeaders();
    return this.authService.getTokenSilently$({ignoreCache: false}).pipe(
      mergeMap((accessToken) => {
        headers = headers.append("authorization", `Bearer ${accessToken}`);
        if(this.authService.getPublicStatus()) {
          headers = headers.append("X-Token", accessToken);
        }
        return this.http.post(url, formData, {headers});
      })
    );
  }

  public getClientInfo() {
    
    if(this.clientInfo) {
      //Cache layer
      this.authService.updateClientState({
        clientId: this.clientInfo.clientId,
        isRewardInitEnabled: this.clientInfo.experimentalConfig && this.clientInfo.experimentalConfig.isRewardInitEnabled ? true : false,
        clientName: this.clientInfo.name,
        email: this.clientInfo.contactEmails[0],
        eventsLogUrl: this.clientInfo.eventsLogUrl,
        apiKey: this.clientInfo.apiKey}),
        this.clientInfo = this.clientInfo;
        return of(this.clientInfo)
    }

    return this.callApi('GET', `${DOMAIN}/integrations/v1/onboarding/client`, true)
    .pipe(
      map(res => {
        if (res.success && res.data) {
          this.authService.updateClientState({
            clientId: res.data.clientId,
            isRewardInitEnabled: res.data.experimentalConfig && res.data.experimentalConfig.isRewardInitEnabled ? true : false,
            clientName: res.data.name,
            email: res.data.contactEmails[0],
            eventsLogUrl: res.data.eventsLogUrl,
            apiKey: res.data.apiKey})
            this.clientInfo = res.data;
            return res.data
        } else {
          this.snackBarService.error('Invalid configuration for the client, please contact admin.')
        }
      })
    )
  }

  uploadFile(file: File, url: string, formData?: FormData, customHeader?) {
    let headers = new HttpHeaders();
    return this.authService.getTokenSilently$({ignoreCache: false}).pipe(
      mergeMap((accessToken) => {
        headers = headers.append("authorization", `Bearer ${accessToken}`);
        if(this.authService.getPublicStatus()) {
          headers = headers.append("X-Token", accessToken);
        }
        return this.http.post(url, formData, {headers});
      })
    );
  }
}
